import axios from 'axios'

export function getAll (controller) {
    return new Promise((resolve, reject) => {
        axios({
            url: process.env.VUE_APP_APIURL + '/api/' + controller,
            method: 'GET',
        })
        .then(resp => {
            resolve(resp)
        })
        .catch(err => {
            reject(err)
        })
    })
}
export function getAllById (controller, id) {
    return new Promise((resolve, reject) => {
        axios({
            url: process.env.VUE_APP_APIURL + '/api/' + controller + '/' + id,
            method: 'GET',
        })
        .then(resp => {
            resolve(resp)
        })
        .catch(err => {
            reject(err)
        })
    })
}
export function getSingle (controller, id) {
    return new Promise((resolve, reject) => {
        axios({
            url: process.env.VUE_APP_APIURL + '/api/' + controller + '/' + id,
            method: 'GET',
        })
        .then(resp => {
            resolve(resp)
        })
        .catch(err => {
            reject(err)
        })
    })
}
export function getSingleMultiKey (controller, id1, id2) {
    return new Promise((resolve, reject) => {
        axios({
            url: process.env.VUE_APP_APIURL + '/api/' + controller + '/' + id1 + '/' + id2,
            method: 'GET',
        })
        .then(resp => {
            resolve(resp)
        })
        .catch(err => {
            reject(err)
        })
    })
}
export function updateSingle (controller, id, obj) {
    return new Promise((resolve, reject) => {
        axios({
            url: process.env.VUE_APP_APIURL + '/api/' + controller + '/' + id,
            data: obj,
            method: 'PUT',
        })
        .then(resp => {
            resolve(resp)
        })
        .catch(err => {
            reject(err)
        })
    })
}
export function updateSingleMultiKey (controller, id1, id2, obj) {
    return new Promise((resolve, reject) => {
        axios({
            url: process.env.VUE_APP_APIURL + '/api/' + controller + '/' + id1 + '/' + id2,
            data: obj,
            method: 'PUT',
        })
        .then(resp => {
            resolve(resp)
        })
        .catch(err => {
            reject(err)
        })
    })
}
export function createSingle (controller, obj) {
    return new Promise((resolve, reject) => {
        axios({
            url: process.env.VUE_APP_APIURL + '/api/' + controller,
            data: obj,
            method: 'POST',
        })
        .then(resp => {
            resolve(resp)
        })
        .catch(err => {
            reject(err)
        })
    })
}

export function deleteSingle (controller, id) {
    return new Promise((resolve, reject) => {
        axios({
            url: process.env.VUE_APP_APIURL + '/api/' + controller + '/' + id,
            method: 'DELETE',
        })
        .then(resp => {
            resolve(resp)
        })
        .catch(err => {
            reject(err)
        })
    })
}
export function deleteSingleMultiKey (controller, id1, id2) {
    return new Promise((resolve, reject) => {
        axios({
            url: process.env.VUE_APP_APIURL + '/api/' + controller + '/' + id1 + '/' + id2,
            method: 'DELETE',
        })
        .then(resp => {
            resolve(resp)
        })
        .catch(err => {
            reject(err)
        })
    })
}
export function sendMail (name, email, subject, claimnumber, message) {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('subject', subject)
    formData.append('claimnumber', claimnumber)
    formData.append('message', message)

    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_APIURL + '/api/sendmail/keepintouch/send',
            formData,
        )
        .then(resp => {
            resolve(resp)
        })
        .catch(err => {
            reject(err)
        })
    })
}

export function submitAssignment (assignmentInfo, files) {
    const formData = new FormData()
    formData.append('clientCompany', assignmentInfo.clientCompany)
    formData.append('clientName', assignmentInfo.clientName)
    formData.append('clientAddress', assignmentInfo.clientAddress)
    formData.append('clientCity', assignmentInfo.clientCity)
    formData.append('clientState', assignmentInfo.clientState)
    formData.append('clientZip', assignmentInfo.clientZip)
    formData.append('clientPhone', assignmentInfo.clientPhone)
    formData.append('clientEmail', assignmentInfo.clientEmail)
    formData.append('claimNumber', assignmentInfo.claimNumber)
    formData.append('insuredLastName', assignmentInfo.insuredLastName)
    formData.append('insuredFirstName', assignmentInfo.insuredFirstName)
    formData.append('lossZip', assignmentInfo.lossZip)
    if (files) {
        for (const file of files) {
            formData.append('lossDoc', file, file.name)
        }
    }
    // formData.append('email', email)
    // formData.append('subject', subject)
    // formData.append('claimnumber', claimnumber)
    // formData.append('message', message)

    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_APIURL + '/api/submitassignment/submit',
            formData,
        )
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err)
            })
    })
}
